import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#8F0020',
                secondary: '#57391E',
                accent: '#FB6542',
                success: '#3F681C',
                'on-surface': '#202020',
            }
        },
        dark: false
    }
});
