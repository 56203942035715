import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import './main.scss'

Vue.config.productionTip = false

import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
  container: "#scrollContainer",
  duration: 650,
  easing: "ease",
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
