<template>
  <v-app>
    <v-main style="height: 100vh; overflow-y: auto" class="bodyText">
      <v-app-bar
        dark
        height="90"
        elevation="7"
        class="appBarGradient"
        absolute
        style="z-index: 2"
      >
        <div class="d-flex" style="width: 100%; height: 100%">
          <div class="d-flex justify-center align-center">
            <v-img
              alt="Logo"
              src="./assets/TopsureLogoNew.svg"
              :width="`${$vuetify.breakpoint.mdAndDown ? '130' : '220'}`"
            ></v-img>
          </div> <!-- Logo -->
          <div
            v-if="!$vuetify.breakpoint.mdAndDown"
            class="flex-grow-1 d-flex align-center justify-end"
          >
            <template v-for="(btn, i) in appBarButtons">
              <a
                :key="i + 'A'"
                v-scroll-to="`#${btn.href}`"
              >
                <v-btn
                  text
                  :class="`${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? null : null : 'mr-15'}`"
                  elevation="0"
                  color="#FFFFFF"
                >
                  <div class="appBarButtonsFont">{{ btn.label }}</div>
                </v-btn>
              </a>
            </template>
          </div> <!-- AppBar Buttons -->
          <div
            v-else
            class="flex-grow-1 d-flex justify-end align-center"
          >
            <v-menu
              bottom
              offset-y
              close-on-click
              close-on-content-click
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="#FFFFFF"
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template> <!-- Burger Icon -->
              <div class="pa-3 d-flex flex-column justify-center align-center" style="background-color: #FFFFFF">
                <template v-for="(btn, i) in appBarButtons">
                  <a
                    :key="i + 'A'"
                    v-scroll-to="`#${btn.href}`"
                  >
                    <v-btn
                      text
                      class="ma-1"
                      :class="`${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? null : null : 'mr-15'}`"
                      elevation="0"
                      color="primary"
                    >{{ btn.label }}</v-btn>
                  </a>
                </template>
              </div> <!-- Btn -->
            </v-menu>
          </div> <!-- AppBar Menu -->
        </div>
      </v-app-bar>
      <div id="scrollContainer" style="overflow-y: auto; position: relative; height: 100%">
        <div
          id="ourBackground"
          class="d-flex"
          style="position: absolute; width: 100%; top: -64px"
          :style="`${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? 'height: 50vh' : 'height: 50vh' : 'height: 59vh'}`"
        >
          <div class="flex-equal flex-grow-1 flex-shrink-0 backgroundImageTopLeft"></div>
          <div class="flex-equal flex-grow-1 flex-shrink-0 backgroundImageTopMiddle"></div>
          <div class="flex-equal flex-grow-1 flex-shrink-0 backgroundImageTopRight"></div>
        </div> <!-- Our Background Images -->
        <div
          id="contentDiv"
          style="margin-top: 20%"
        >
          <div class="d-flex justify-center align-center pb-14 ourBackgroundGradient" style="box-shadow: 0 2px 6px 0 #000000">
            <v-card
              style="border-radius: 0; opacity: 90%"
              :width="`${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
            >
              <div class="d-flex flex-column">
                <div
                  class="d-flex justify-center align-center py-3 secondary--text headerTitle"
                  style="height: 100%"
                  :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '3em' : '2.75em' : '3.5em'};`"
                >Our Background</div>
                <div
                  class="pb-5 d-flex"
                  :class="`${$vuetify.breakpoint.mdAndDown ? 'flex-column' : null}`"
                >
                  <div class="pa-1 flex-grow-1 flex-equal flex-shrink-0 d-flex flex-column">
                    <v-expansion-panels flat>
                      <v-expansion-panel
                        v-for="(obj, i) in ourBackgroundTextFirstHalfArr"
                        :key="i + 'A'"
                      >
                        <v-expansion-panel-header hide-actions>
                          <div
                            :key="i + 'B'"
                            class="primary white--text pa-1 pl-2"
                            :style="`height: ${obj.title.height}px; font-size: ${$vuetify.breakpoint.xs ? '1.25rem' : '1.5rem'}`"
                          >{{ obj.title.text }}</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            :key="i + 'C'"
                            class="pt-2"
                            :style="`height: ${obj.title.height}px; font-size: ${$vuetify.breakpoint.xs ? '1.25rem' : '1.5rem'}`"
                          >
                            <v-divider></v-divider>
                            {{ obj.body.text }}
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div
                    class="pa-1 flex-grow-1 flex-equal flex-shrink-0 d-flex flex-column"
                    :class="`${$vuetify.breakpoint.mdAndDown ? 'mb-8' : null}`"
                  >
                    <v-expansion-panels flat>
                      <v-expansion-panel
                        v-for="(obj, i) in ourBackgroundTextSecondHalfArr"
                        :key="i + 'A'"
                      >
                        <v-expansion-panel-header hide-actions>
                          <div
                            :key="i + 'B'"
                            class="font-weight-light primary white--text pa-1 pl-2"
                            :style="`height: ${obj.title.height}px; font-size: ${$vuetify.breakpoint.xs ? '1.25rem' : '1.5rem'}`"
                          >{{ obj.title.text }}</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            :key="i + 'C'"
                            class="font-weight-light pt-2"
                            :style="`height: ${obj.title.height}px; font-size: ${$vuetify.breakpoint.xs ? '1.25rem' : '1.5rem'}`"
                          >
                            <v-divider></v-divider>
                            {{ obj.body.text }}
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
              </div>
            </v-card>
          </div> <!-- Our Background -->
          <div
            id="ourBackground"
            class="d-flex flex-column justify-center align-center"
            style="width: 100%"
          >
            <div
              id="ourProducts"
              class="d-flex flex-column"
              style="width: 95%"
            >
              <div
                class="my-3 d-flex justify-center align-center font-weight-light secondary--text headerTitle"
                :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '3em' : '2.75em' : '3.5em'};`"
              >Our Products</div> <!-- Title -->
              <div class="d-flex justify-center flex-wrap">
                <template v-for="(product, i) in productDifferencesArr">
                  <div
                    :key="i + 'A'"
                    class="d-flex flex-column"
                    style="height: 600px"
                    :style="`${$vuetify.breakpoint.mdAndDown ? null : 'width: 31%'}`"
                  >
                    <div
                      class="productBackgroundImage d-flex flex-column align-end"
                      :style="`background-image: url(/products/${product.image})`"
                    >
                      <div class="productTextContainer" style="max-width: 350px; min-width: 350px">
                        <v-card-title class="text-break">
                          <div class="d-flex" style="width: 100%">{{ product.title }}</div>
                          <div style="border: 1px solid #FFFFFF; width: 65%"></div>
                        </v-card-title>
                        <div class="pl-2 pr-1" style="font-size: 1.1rem">
                          <div
                            v-for="(item, j) in product.productsArr"
                            :key="j + 'A'"
                            class="pa-2"
                          >{{ item }}</div>
                        </div>
                      </div>
                    </div>
                    <div :key="i + 'C'" style="height: 7%"></div>
                  </div>
                  <div :key="i + 'B'" style="width: 2%"></div>
                </template>
              </div> <!-- All Products -->
            </div> <!-- Our Products -->
            <div
              id="underwriting"
              class="py-4 d-flex justify-center align-center underwritingGradient white--text"
              style="width: 100%; box-shadow: 0 2px 6px 0 #000000"
            >
              <div
                class="d-flex"
                :class="`${$vuetify.breakpoint.mdAndDown ? 'flex-column justify-center align-center' : null}`"
                style="width: 100%; height: 100%"
              >
                <div
                  class="mb-5 d-flex flex-grow-1 align-center flex-grow-1 flex-equal flex-shrink-0 headerTitle"
                  style="padding-left: 8%"
                  :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '3em' : '2.75em' : '5em'};`"
                >Underwriting</div> <!-- Title -->
                <div id="documents" class="mx-7 flex-grow-1 flex-equal flex-shrink-0 d-flex justify-center align-center flex-column">
                  <template v-for="(item, i) in underwritingArr">
                    <div
                      :key="i + 'A'"
                      class="py-4 font-weight-regular d-flex"
                      style="width: 100%; font-size: 1.25rem"
                    >
                      <div class="d-flex justify-center align-center"
                           style="height: 100%; width: 30px">
                        <v-img
                          src="./assets/TickMark.svg"
                          style="height: 23px; width: 24px"
                        ></v-img>
                      </div>
                      <div class="pl-5" style="height: 100%">{{ item }}</div>
                    </div>
                  </template>
                </div> <!-- Body -->
              </div>
            </div> <!-- Underwriting -->
            <div
              class="py-4 d-flex flex-column"
              style="width: 95%"
            >
              <div
                class="my-3 d-flex justify-center align-center font-weight-light secondary--text headerTitle"
                :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '3.5em' : '3.25em' : '4em'};`"
              >Documents</div> <!-- Title -->
              <div class="d-flex flex-wrap" style="width: 100%">
                <template v-for="(doc, i) in documentsArr">
                  <div
                    :key="i + 'A'"
                    class="py-2 d-flex justify-center align-center"
                    style="width: 50%; max-width: 50%; min-width: 50%; height: 100%"
                  >
                    <div style="max-width: 50%; min-width: 50%">
                      <a
                        :href="doc.href"
                        target="_blank"
                        style="text-decoration: none"
                        :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '2rem' : '3.25rem' : '1.75em'};`"
                      >
                        <v-icon color="primary" large>mdi-file-document-outline</v-icon>
                        {{ doc.label }}
                      </a>
                    </div>
                  </div>
                </template>
              </div> <!-- All Products -->
            </div> <!-- Documents -->
          </div> <!-- Our Products and Underwriting -->
          <div
            id="contactUs"
            class="d-flex flex-column white--text"
            style="position: relative"
            :style="`height: ${$vuetify.breakpoint.mdAndDown ? '120vh' : '80vh'}`"
          >
            <div class="backgroundImageBottom">
              <div class="backgroundImageGradient"></div>
            </div>
            <div
              class="pt-5 d-flex justify-start"
              :class="`${$vuetify.breakpoint.smAndDown ? 'flex-column' : null}`"
              style="position: absolute; width: 100%; height: 100%"
            >
              <div
                class="d-flex flex-column"
                :style="`width: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.smAndDown ? '100%' : '50%' : '40%; padding-left: 9%'}`"
              >
                <div class="pb-1 d-flex flex-column">
                  <div
                    class="d-flex justify-start align-center headerTitle"
                    :class="`${$vuetify.breakpoint.smAndDown ? 'pl-5' : null}`"
                    :style="`font-size: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? '2.5em' : '2.75em' : '4em'};`"
                  >Contact us</div> <!-- Title -->
                  <div
                    class="d-flex flex-column"
                    :class="`${$vuetify.breakpoint.smAndDown ? 'flex-column pl-5' : null}`"
                    style="font-size: 1.5rem"
                  >
                    <template v-for="(item, i) in contactUsArr">
                      <div
                        :key="i + 'A'"
                        class="d-flex"
                      >
                        <div class="font-weight-regular" style="font-size: 1.5rem">
                          {{ item.title }}:&nbsp;
                        </div>
                        <div class="d-flex align-end"
                             style="font-weight: 500; font-size: 1.25rem">
                          {{ item.content }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div> <!-- Contact Us Text -->

                <div
                  class="d-flex justify-center align-center py-3"
                  style="position: relative"
                  :style="`height: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.smAndDown ? '200px' : '50%' : '40%'}`"
                >
                  <div
                    id="map"
                    :class="`${!$vuetify.breakpoint.mdAndDown ? 'mt-13' : null}`"
                    :style="`${$vuetify.breakpoint.mdAndDown ? 'width: 90%; height: 100%' : 'width: 100%; height: 100%'}`"
                  ></div> <!-- Google Map -->
                  <div
                    v-if="!$vuetify.breakpoint.mdAndDown"
                    style="position: absolute; background-color: #FFFFFF; top: 50px; left: 17px"
                  >
                    <v-card
                      raised
                      rounded
                      class="pa-3"
                    >
                      <div class="d-flex flex-column">
                        <div class="secondary--text" style="font-size: 1.75rem">Topsure</div>
                        <div style="font-size: 1.1rem">119 Ruiterweg | Mokopane | 0601</div>
                      </div>
                    </v-card>
                  </div> <!-- Info Card -->
                </div>

              </div>

              <div
                class="flex-grow-1 flex-equal d-flex flex-column align-center"
                :class="`${$vuetify.breakpoint.mdAndDown ? null : 'justify-center'}`"
              >
                <div :style="`width: ${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.xsOnly ? '80%' : '70%' : '80%' : '50%'}`">
                  <div class="d-flex flex-column pb-5" style="font-size: 2rem">
                    <div>
                      <div class="headerTitle">Get in Touch</div>
                      <div style="border: 1px solid #FFFFFF; width: 15%"></div>
                    </div>
                  </div>
                  <v-form ref="contactUs" @submit.stop.prevent>
                    <v-text-field
                      solo
                      dense
                      filled
                      outlined
                      hide-details
                      class="mb-2"
                      background-color="white"
                      prepend-inner-icon="mdi-account"
                      placeholder="Name and Surname"
                      v-model="nameAndSurname"
                      :rules="[validationRules.vRequired]"
                    ></v-text-field>
                    <v-text-field
                      background-color="white"
                      solo
                      dense
                      filled
                      outlined
                      hide-details
                      class="mb-2"
                      prepend-inner-icon="mdi-phone"
                      placeholder="Mobile"
                      v-model="mobile"
                      :rules="[validationRules.vRequired, validationRules.vCellNo]"
                    ></v-text-field>
                    <v-text-field
                      background-color="white"
                      solo
                      dense
                      filled
                      outlined
                      hide-details
                      class="mb-2"
                      prepend-inner-icon="mdi-email"
                      placeholder="Email"
                      v-model="email"
                      :rules="[validationRules.vRequired, validationRules.vEmail]"
                    ></v-text-field>
                    <v-textarea
                      background-color="white"
                      solo
                      dense
                      filled
                      outlined
                      rows="5"
                      no-resize
                      hide-details
                      class="mb-2"
                      prepend-inner-icon="mdi-message"
                      placeholder="Message"
                      v-model="message"
                      :rules="[validationRules.vRequired]"
                    ></v-textarea>
                  </v-form> <!-- Form Inputs -->
                  <div class="d-flex align-center justify-end">
                    <v-btn
                      small
                      color="primary"
                      :loading="submitBtnLoading"
                      @click="submitMessage"
                    >Submit</v-btn>
                  </div> <!-- Submit Button -->
                </div>
              </div> <!-- Contact Us Form -->

            </div>

            <div
              class="d-flex justify-center align-center pb-1"
              style="background-color: rgba(255, 255, 255, 100%); height: 70px"
            >
              <div
                class="d-flex justify-center align-center"
                :style="`${$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xs ? 'width: 24vw' : 'width: 14vw' : 'width: 8vw'}`"
              >
                <v-img
                  alt="Logo"
                  src="./assets/TopsureLogoNew.svg"
                ></v-img>
              </div> <!-- Logo -->
            </div> <!-- Footer -->

          </div> <!-- Contact Us -->
        </div> <!-- Content -->
      </div>
    </v-main>
  </v-app>

</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import validationRules from "@calvin-coomer/fns/validations";
export default {
  data() {
    return {
      console,

      appBarTop: true,

      submitBtnLoading: false,

      nameAndSurname: null,
      mobile: null,
      email: null,
      message: null,

      appBarButtons: [
        {
          label: 'Our Background',
          href: 'ourBackground',
        },
        {
          label: 'Our Products',
          href: 'ourProducts',
        },
        {
          label: 'Underwriting',
          href: 'underwriting',
        },
        {
          label: 'documents',
          href: 'documents',
        },
        {
          label: 'Contact Us',
          href: 'contactUs',
        },
      ],
      ourBackgroundTextArr: [
        {
          title: {
            text: 'Our Proprietary Insurance Products',
            height: null
          },
          body: {
            text: `At Topsure, we understand that every individual and business is unique. That's why we offer a
                            diverse portfolio of proprietary insurance products. These products have been crafted with precision
                            and expertise to provide you with the utmost protection and peace of mind.`,
            height: null
          }
        },
        {
          title: {
            text: 'Commercial and Agricultural Products',
            height: null
          },
          body: {
            text: `For businesses, farms, and agricultural enterprises, we offer a specialized suite of products
                            designed to address the distinct challenges of the commercial and agricultural sectors. Our commercial
                            and Agri products are tailored to support your unique needs, ensuring that your operations are
                            well-protected against unforeseen risks. With our comprehensive coverage options, you can focus on
                            what you do best while we take care of your insurance needs.`,
            height: null
          }
        },
        {
          title: {
            text: 'Commitment to Multi-Market 3 Principles',
            height: null
          },
          body: {
            text: `At Topsure, we uphold the Multi-Mark 3 principles with unwavering dedication. These principles
                            revolve around transparency, fairness, and customer-centricity. When you choose us, you're choosing an
                            insurance partner that values your trust and strives to provide you with clear, equitable, and
                            customer-focused insurance solutions. We are committed to building lasting relationships with our
                            clients based on these core principles.`,
            height: null
          }
        },
        {
          title: {
            text: 'Product of Choice',
            height: null
          },
          body: {
            text: `At Topsure, we believe that choosing the right insurance provider is a significant decision.
                            We are dedicated to being your product of choice by offering a diverse range of insurance solutions,
                            upholding ethical standards, and delivering exceptional service. Your security and satisfaction are
                            our top priorities.`,
            height: null
          }
        },
        {
          title: {
            text: 'Endorsed by Renasa',
            height: null
          },
          body: {
            text: `For added peace of mind, all our products are underwritten by Renasa, a distinguished name in
                            the insurance industry. Renasa's solid reputation for reliability and financial stability ensures that
                            our policies are backed by a trusted partner. With Renasa's support, you can be confident that your
                            insurance needs are in capable hands.`,
            height: null
          }
        },
      ],
      productDifferencesArr: [
        {
          title: 'Property and Asset Insurance',
          image: 'productsOne.jpg',
          productsArr: [
            'Fire section',
            'Crops and stacks department',
            'Limited coverage below the flood line',
            'Tobacco – field to floor',
            'Buildings combined',
            'Office content',
            'Business interruption - broiler chickens/ must be material damage',
            'Accounts receivable',
            'Electronic equipment',
          ]
        },
        {
          title: 'Motor Insurance',
          image: 'productsTwo.jpg',
          productsArr: [
            'Motor insurance only under comprehensive coverage',
            'Inception value protector',
            'Credit shortfall cover',
            'Increase value protector',
            'Deposit protector',
            'Motor service plan protector',
            'Extra tyre or tracks cover',
            'Sites liability',
            'Caravans hire out',
            'Loss of use – any amount',
            'Death of employee as result of accident',
          ]
        },
        {
          title: 'Equipment and Asset Coverage',
          image: 'productsThree.jpg',
          productsArr: [
            'Contents of spraying equipment',
            'Unspecified agri equipment attach',
            'Precision farming equipment',
            'Excess reducers',
            'Own damage',
            'Theft/hijack excess reducer',
            'Third party excess reducer',
            'Penalty',
            'Windscreen',
            'Spare-wheel and tools excess reducer',
          ]
        },
        {
          title: 'Liability Insurance',
          image: 'productsFour.jpg',
          productsArr: [
            'Robbery not Theft',
            'Money',
            'Glass',
            'Transit',
            'Business all risk',
            'Accidental damage',
            'Broad-form liability',
            'Pollution liability - envirosure',
            'Employers liability',
          ]
        },
        {
          title: 'Specialty Insurance',
          image: 'productsFive.jpg',
          productsArr: [
            'Stated benefits',
            'Group accident benefits',
            'Livestock',
            'Pedigreed animals',
            'Single transit – general and livestock',
            'Machinery breakdown',
            'Loss of profits',
            'Deterioration of stock',
            'Solar – attach to building and freestanding',
            'Pivots',
          ]
        },
        {
          title: 'Personal Insurance',
          image: 'productsSix.jpg',
          productsArr: [
            'House-owners',
            'Householders',
            'Personal all risk',
            'Personal liability',
            'Extended liability',
          ]
        },
        {
          title: 'Marine and Watercraft Insurance',
          image: 'productsSeven.jpg',
          productsArr: ['Motorboats/ Yachts /Small crafts']
        },
      ],
      underwritingArr: [
        'The right risk at the right premium',
        'We provide insurance coverage for amounts up to 60 million Rand',
        'Motor vehicle insurance is not available as a standalone product'
      ],
      documentsArr: [
        {
          label: 'Agri Policy Wording',
          href: '/policy_wording.pdf',
        },
      ],
      contactUsArr: [
        {
          title: 'Address',
          content: '119 Ruiterweg, Mokopane, 0601'
        },
        {
          title: 'Email',
          content: 'info@topsure.co'
        },
        {
          title: 'Tel',
          content: '015 000 6132'
        },
      ],
    }
  },
  methods: {
    async submitMessage() {
      if (!this.$refs.contactUs.validate())
        return;

      try {

        this.submitBtnLoading = true;

        (await Email.send({
          Host: "smtp.elasticemail.com",
          Username: "website@topsure.co",
          Password: "B7B9BB301EB69E93F231F72E7C42BDB8886E",
          To: 'info@topsure.co.za',
          From: "info@topsure.co",
          Subject: `New Message From Topsure`,
          Body: `<html lang="en">
<body>
<div style="border: 1px solid black; padding-left: 15px; padding-top: 10px; border-radius: 12px; width: 30%; color: #000000">
    <div style="display: flex; padding-bottom: 7px">
        <div style="font-size: 18px">Name and Surname:&nbsp;</div>
        <div style="padding-top: 4px">${ this.nameAndSurname }</div>
    </div>
    <div style="display: flex; padding-top: 7px; padding-bottom: 7px">
        <div style="font-size: 18px">Mobile Number:&nbsp;</div>
        <div style="padding-top: 4px">${ this.mobile }</div>
    </div>
    <div style="display: flex; padding-top: 7px; padding-bottom: 7px">
        <div style="font-size: 18px">Email Address:&nbsp;</div>
        <div style="padding-top: 4px">${ this.email }</div>
    </div>
    <div style="display: flex; padding-top: 7px; padding-bottom: 7px">
        <div style="font-size: 18px; color: #000000">Message:&nbsp;</div>
        <div style="color: #000000; padding-top: 4px">${ this.message }</div>
    </div>
</div>
</body>
</html>`
        }).then((message) => {
          alert(message);
        }).catch(e => {
          console.log(e);
          alert('Error Sending Mail');
        }))
      } catch (e) {
        console.log(e);
        alert('Error Sending Mail');
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  computed: {
    validationRules() {
      return validationRules
    },
    ourBackgroundTextFirstHalfArr() {
      const half = Math.ceil(this.ourBackgroundTextArr.length / 2);
      const returnArr = [];
      for (let i = 0; i < half; i++) {
        returnArr.push(this.ourBackgroundTextArr[i]);
      }
      return returnArr;
    },
    ourBackgroundTextSecondHalfArr() {
      const arr = JSON.parse(JSON.stringify(this.ourBackgroundTextArr));
      const half = Number.isInteger(this.ourBackgroundTextArr.length / 2) ? this.ourBackgroundTextArr.length / 2 : Math.floor(this.ourBackgroundTextArr.length / 2);
      const newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (i < arr.length - half)
          continue;
        newArr.push(arr[i]);
      }
      const returnArr = [];
      for (let i = 0; i < half; i++) {
        returnArr.push(newArr[i]);
      }
      return returnArr;
    },
  },
  created() {
    const loader = new Loader({
      apiKey: "AIzaSyA_lXTpTT7v2-vGHXmSmrcwAO2EBuixPRA",
      version: "weekly",
    });

    const mapOptions = {
      center: {
        lat: -24.1895823,
        lng: 29.0085215
      },
      zoom: 18,
      disableDefaultUI: true,
    };

    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById("map"), mapOptions);

        new google.maps.Marker({
          position: {
            lat: -24.1895823,
            lng: 29.0085215
          },
          map: map,
          query: 'Topsure',
        });
      })
      .catch(e => {
        console.log(e);
      });

  },
  mounted() {
    window.addEventListener('scroll', () => {
      const div = document.getElementById('scrollContainer');
      this.appBarTop = !(div.scrollTop > 80);
    }, true);
  }
};
</script>

<style>
html {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: 'LeagueSpartanBold';
  src: url("fonts/LeagueSpartanBold.ttf") format('truetype');
}
.appBarButtonsFont {
  font-family: 'LeagueSpartanBold', sans-serif !important;
  font-size: 17px;
  color: #FFFFFF;
}

@font-face {
  font-family: 'LeagueSpartanMedium';
  src: url("fonts/LeagueSpartanMedium.ttf") format('truetype');
}
.headerTitle {
  font-family: 'LeagueSpartanMedium', sans-serif !important;
}

@font-face {
  font-family: 'GlacialIndifferenceRegular';
  src: url("fonts/GlacialIndifferenceRegular.otf") format('opentype');
}
.bodyText {
  font-family: 'GlacialIndifferenceRegular', sans-serif !important;
}

.appBarGradient {
  /*background: rgb(255,255,255);*/
  /*background: linear-gradient(*/
  /*  90deg,*/
  /*  rgba(255,255,255,1) 0%,*/
  /*  rgba(215,163,175,1) 29%,*/
  /*  rgba(210,153,166,1) 30%,*/
  /*  rgba(143,0,32,1) 75%,*/
  /*  rgba(116,27,31,1) 90%,*/
  /*  rgba(107,36,31,1) 91%,*/
  /*  rgba(87,57,30,1) 100%*/
  /*);*/
  background: rgb(255,255,255);
  background: linear-gradient(
    90deg,
    rgba(255,255,255,1) 0%,
    rgba(205,144,156,1) 35%,
    rgba(196,124,139,1) 45%,
    rgba(188,107,124,1) 51%,
    rgba(163,51,75,1) 73%,
    rgba(159,42,68,1) 78%,
    rgba(140,0,30,1) 88%,
    rgba(0,0,0,1) 100%
  );
}
.ourBackgroundGradient {
  /*background: rgb(255,255,255);*/
  /*background: linear-gradient(*/
  /*  270deg,*/
  /*  rgba(255,255,255,1) 0%,*/
  /*  rgba(210,153,166,1) 10%,*/
  /*  rgba(210,153,166,1) 20%,*/
  /*  rgba(161,41,68,1) 63%,*/
  /*  rgba(143,0,32,1) 75%,*/
  /*  rgba(116,27,31,1) 90%,*/
  /*  rgba(107,36,31,1) 91%,*/
  /*  rgba(87,57,30,1) 100%*/
  /*);*/
  background: rgb(255,255,255);
  background: linear-gradient(
    270deg,
    rgba(255,255,255,1) 0%,
    rgba(205,144,156,1) 32%,
    rgba(196,124,139,1) 42%,
    rgba(188,107,124,1) 51%,
    rgba(163,51,75,1) 62%,
    rgba(159,42,68,1) 71%,
    rgba(140,0,30,1) 82%,
    rgba(121,0,26,1) 89%,
    rgba(0,0,0,1) 100%
  );
}
.productTextContainer {
  background-color: var(--v-primary-base);
  opacity: 80%;
  color: #FFFFFF;
  height: 100%;
}
.productBackgroundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.backgroundImageTopLeft {
  background-image: url("assets/header/imgTopLeft.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: -2px;
  margin-left: -1px;
}
.backgroundImageTopMiddle {
  background-image: url("assets/header/imgTopMiddle.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.backgroundImageTopRight {
  background-image: url("assets/header/imgTopRight.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -2px;
}
.backgroundImageBottom {
  background-image: url("assets/footer/footer.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 90%;
}
.backgroundImageGradient {
  width: 100%;
  height: 100%;
  background: rgb(143,0,32);
  background: linear-gradient(90deg, rgba(143,0,32,95%) 0%, rgba(143,0,32,20%) 75%, rgba(143,0,32,0%) 100%);
}
.underwritingGradient {
  /*background: rgb(143,0,32);*/
  /*background: linear-gradient(270deg, rgba(143,0,32,1) 0%, rgba(87,57,30,1) 100%);*/
  background: rgb(140,0,30);
  background: linear-gradient(270deg, rgba(140,0,30,1) 0%, rgba(0,0,0,1) 100%);
}
</style>